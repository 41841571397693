import React from "react";
import "./cardJob.scss";
import { Col, Row } from "react-grid-system";

const CardJob = (props) => {
  return (
    <div>
      <a
        href={props.jobLink}
        target="_blank"
        rel="noreferrer"
        key={props.jobId}
      >
        <div className="card-job">
          <Row className="row">
            <Col lg={12} className="col">
              <div className="card-job__content">
                {/* <span>{props.jobDepartment}</span> | <span>Full Time</span> */}
                <span>{props.jobDepartment}</span>
                <p>
                  <strong>{props.jobTitle}</strong>
                </p>
                {/* <div className="job-location">
                <span>Hybrid</span> | <span>{props.jobLocation}</span>
                </div> */}
                <div className="job-location">
                  <span>{props.jobLocation}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </a>
    </div>
  );
};

export default CardJob;
