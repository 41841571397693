import React, { useEffect } from "react";
import { useState } from "react";
import arrowImg from "../../../images/down-icon.svg";
import "./select.scss";
import ClickAwayListener from "../../click-away-listener";

function Select({ children, onChange, className='' }) {
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState({});

  const handleInputClick = () => {
    setExpanded(!expanded);
  };
  const hanldeItemClick = (item) => {
    setSelected(item);
    setExpanded(false);
  };
  let optionsArr = (() => {
    if (Array.isArray(children)) {
      return children.map((item) => {
        return item.props;
      });
    } else if (typeof children === "object") {
      return [children.props];
    } else {
      return [];
    }
  })();

  useEffect(() => {
    optionsArr.forEach((item) => {
      if (item.selected) {
        setSelected(item);
      }
    });
  }, []);

  useEffect(() => {
    onChange && onChange(selected.value);
  }, [selected]);

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <div className={`select-root ${className}`}>
        <div
          className={`input-box ${expanded ? "expanded" : ""}`}
          tabIndex={0}
          role="button"
          data-expanded={expanded}
          id="my-custom-select"
          onClick={handleInputClick}
        >
          <span>{selected.children}</span>
          <img src={arrowImg} alt="Arrow"></img>
        </div>
        <div
          className={`dropdown-box ${expanded ? "expanded" : ""}`}
          tabIndex={-1}
        >
          <ul tabIndex={-1}>
            {optionsArr.map((item) => (
              <li
                key={item.value}
                className={`${item.value === selected ? "active" : ""}`}
                tabIndex={-1}
                data-value={item.value}
                onClick={() => hanldeItemClick(item)}
              >
                {item.children}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default Select;
