import React from "react";
import { Col, Row } from "react-grid-system";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import "./teamDetails.scss";

function TeamDetails({
    teamName,
    designation,
    imgRef,
    description,
    socialLink,
    hidePopup,
    className,
    ...props
}) {
    return (
        <Popup trigger={
            <div
                {...props}>
                <article className={`card-team ${className}`}>
                    {imgRef ? (
                        <div className="card-team__media">
                            <img
                                src={imgRef}
                                alt={teamName ? teamName : ""}
                                loading="lazy"
                                width={358}
                                height={358}
                            />
                        </div>
                    ) : ""}
                    {teamName ? (
                        <p className="team-title"><strong>{teamName}</strong></p>
                    ) : ""}
                    {designation ? (
                        <p className="team-designation font-18" dangerouslySetInnerHTML={{ __html: designation }}></p>
                    ) : ""}
                </article>
            </div>
        } modal disabled={hidePopup}>
            {close => (
                <div className="modal card-team__modal">
                    <div className="modal-header text-right">
                        <button className="close" onClick={close}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.456" height="25.456" viewBox="0 0 25.456 25.456">
                                <g id="icon-popup-close" transform="translate(-996.772 -249.772)">
                                    <line id="Line_3590" data-name="Line 3590" x2="30" transform="translate(998.894 251.894) rotate(45)" fill="none" stroke="#a84192" strokeLinecap="round" strokeWidth="3" />
                                    <line id="Line_3591" data-name="Line 3591" x2="30" transform="translate(1020.107 251.894) rotate(135)" fill="none" stroke="#a84192" strokeLinecap="round" strokeWidth="3" />
                                </g>
                            </svg>
                        </button>
                    </div>
                    <div
                        {...props} className="card-team modal-body">
                        <Row >
                            <Col lg={4}>
                                {imgRef ? (
                                    <div className="card-team__media">
                                        <img
                                            src={imgRef}
                                            alt={teamName ? teamName : ""}
                                            loading="lazy"
                                        />
                                    </div>
                                ) : ""}
                            </Col>
                            <Col lg={8}>
                                <div className="head gradient-border">
                                    {teamName ? (
                                        <span className="team-title"><strong>{teamName}</strong></span>
                                    ) : ""}
                                    {designation ? (
                                        <span className="team-designation font-18">{designation}</span>
                                    ) : ""}
                                </div>
                                <div className="description" dangerouslySetInnerHTML={{ __html: description }}>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}

        </Popup>
    );
}

export default TeamDetails;
