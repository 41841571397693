
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react';
import { Context } from '../../redux/store';
import ReactVisibilitySensor from 'react-visibility-sensor';


function Section({ children, id }) {
  const myRef = useRef();
  const [offset, setOffset] = useState(0);
  const { state, dispatch } = useContext(Context);
  const [isVisible, setIsvisible] = useState(false)

  function handleChange(iv) {
    setIsvisible(iv)
  }

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  useEffect(() => {
    const a = myRef.current.getBoundingClientRect()
    // console.log(a.height, Math.abs(a.top))
    // console.log(a.top,a.height)
    if (isVisible) {
      if (a.top < 200) {
        dispatch({ type: "SET_ACTIVE_ID", payload: id, })
      }
    }else{
      if(state.activeId===id){
        dispatch({ type: "SET_ACTIVE_ID", payload: null, })
      }
    }

  }, [offset])

  return (
    <ReactVisibilitySensor
      onChange={handleChange}
      partialVisibility
    >
      <div ref={myRef} className='' id={id}>
        {children}
      </div>
    </ReactVisibilitySensor>
  )
}

export default Section