import React from 'react';
import "./banner.scss";
import FluidContent from '../fluidContent';
import heroImg from "../../images/home/hero-img-mobile.webp";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function Banner() {
    const fadeRef = React.useRef([]);
    fadeRef.current = [];

    const fadeToRefs = (fade) => {
        if (fade) {
            fadeRef.current.push(fade);
        }
    };

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        fadeRef.current.forEach((fade) => {
            gsap.set(fade, {
                opacity: 0,
                y: 100,
            });

            gsap.to(fade, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                scrollTrigger: {
                    trigger: fade,
                },
            });
        });
    }, []);
    return (
        <FluidContent className="banner" style={{}}>
            <div className="centered-content">
                <div className="banner-content" ref={fadeToRefs}>
                    <h1>Empowering <span className="gradient-text">Patient-Centric</span> <span className="gradient-text">Advances</span> <br /> In Cancer Care</h1>
                    <p className='font-bold'>ARTBIO is redefining cancer care by developing a new class of alpha radioligand therapies (ART) and building the ecosystem that maximizes their potential. </p>
                </div>
            </div>
            <div className="banner-mobile">
                <img
                    src={heroImg}
                    alt="Banner Background"
                    className='mobile-only'
                    width={375}
                    height={320}
                    loading='lazy'
                />
            </div>

        </FluidContent>
    );
}

export default Banner;