import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import TabList from './TabList';
import TabPanel from './TabPanel';
import Tab from './Tab';
import "./responsiveTab.scss";
import Select from '../custom/select';
import Option from '../custom/select/option';

function ResponsiveTabs({ children }) {
  const [active, setActive] = useState(0);

  const handleChange = (tab) => {
    setActive(parseInt(tab))
  }
  const tabLists = children[0]?.props?.children ?? []
  const tabPanels = children.filter((_, index) => index !== 0);


  const optionStyle = {
    padding: '10px',
    borderRadius: '4px',
  }

  return (
    <div className='tab-wrap'>
      <Row className='row'>
        <Col lg={3} xs={12} className='left border-right'>
          {/* display on pc/laptop screen */}
          <ul className='react-tabs__tab--list'>
            {tabLists.map((item, index) => (
              <li
                className={`react-tabs__tab ${active == index ? 'active' : ''}`}
                key={'tablist' + index}
                onClick={() => handleChange(index)}
              >
                {item}
              </li>
            ))}
          </ul>

          {/* display on mobile screeen */}
          <Select onChange={item => handleChange(item)}>
            {tabLists.map((item, index) => (
              <Option value={index} key={item} selected={active==index}>{item}</Option>
            ))}
          </Select>
        </Col>
        <Col lg={9} xs={12} className='right'>
          {tabPanels.map((item, index) => (
            <div key={'tab-content' + index} style={{ optionStyle, display: active === index ? 'block' : 'none' }}>
              {item}
            </div>
          ))}
        </Col>
      </Row>
    </div>
  )
}

export default ResponsiveTabs
export { Tab, TabPanel, TabList }